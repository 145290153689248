import { Helmet } from 'react-helmet-async';
// @mui
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  MenuItem,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

export default function OrderDetailsPage() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState();
  const [billing, setBilling] = useState();
  const [shipping, setShipping] = useState();
  const [total, setTotal] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [selectedValue, setSelectedValue] = useState('');
  const [trackId, setTrackId] = useState('');
  const [showTextBox, setShowTextBox] = useState();

  const { orderId } = useParams();

  const handleChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedValue(selectedOption);

    // Check if the selected option is "option2" to show the text box
    setShowTextBox(selectedOption === 'Shipped');
    console.log('Selected Value:', selectedOption);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://khadi-backend.gitdr.com/api/orders/${orderId}`);
      console.log(response.data);
      setData(response.data.order.Orderdetails.Variant);
      setName(response.data.order.User);
      setBilling(response.data.order);
      setShipping(response.data.order);
      setPaymentMethod(response.data.order);
      setTotal(response.data.order);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const updateStatus = async () => {
    try {
      const apiEndpoint = `https://khadi-backend.gitdr.com/api/orders/${orderId}`;

      // Assuming your API expects a JSON body with the 'status' property
      const requestBody = {
        status: selectedValue,
        tracking_number: trackId,
      };

      // Send the API request using the fetch API with async/await
      const response = await fetch(apiEndpoint, {
        method: 'PUT', // Or 'POST' depending on your API
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (selectedValue === '') {
        alert('Please Update Status');
      } else {
        const data = await response.json();
        // Handle the response data if needed
        //console.log('Status updated successfully:', data);
        alert('status updated');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  useEffect(() => {}, [selectedValue, trackId]);

  return (
    <>
      <Helmet>
        <title> Order Details</title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          OrderDetails
        </Typography>

        <Card sx={{ p: 8 }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <CardContent>
              <Box width="auto" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box>
                  <Typography gutterBottom variant="h9" sx={{ color: 'gray', fontSize: '16px' }}>
                    Name
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', marginBottom: '20px', fontWeight: 400 }}
                  >
                    {name.user_name}
                  </Typography>

                  <Typography sx={{ marginBottom: '20px', color: 'gray', fontSize: '16px' }}>
                    Billing Address
                  </Typography>
                  <Typography variant="h6" sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', fontWeight: 400 }}>
                    {billing.address_type}, {billing.street},
                    <br />
                    {billing.city}, {billing.state},
                    <br />
                    {billing.country}, {billing.postal_code}
                  </Typography>
                  <Typography gutterBottom variant="h7" sx={{ lineHeight: 5, color: 'gray' }}>
                    Status
                    <br />
                  </Typography>

                  <FormControl sx={{ width: '150px' }}>
                    <TextField label="select status" select value={selectedValue} onChange={handleChange}>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="cancelled">cancelled</MenuItem>
                      <MenuItem value="Processing">Processing</MenuItem>
                      <MenuItem value="Shipped">Shipped</MenuItem>
                      <MenuItem value="Delivered">Delivered</MenuItem>
                    </TextField>
                  </FormControl>
                </Box>
                <Box>
                  <Box>
                    <Typography gutterBottom variant="h9" sx={{ color: 'gray' }}>
                      Date :
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', color: 'gray', marginBottom: '20px' }}
                    >
                      Id :
                    </Typography>
                  </Box>
                  <Typography sx={{ marginBottom: '20px', fontSize: '16px', color: 'gray' }}>
                    Shipping Address
                  </Typography>
                  <Typography variant="h6" sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: '18px', fontWeight: 400 }}>
                    {shipping.address_type}, {shipping.street},
                    <br />
                    {shipping.city}, {shipping.state},
                    <br />
                    {shipping.country}, {shipping.postal_code}
                  </Typography>

                  {showTextBox && (
                    <TextField
                      onChange={(e) => setTrackId(e.target.value)}
                      value={trackId}
                      label=" Shipping Track Id"
                      variant="outlined"
                      sx={{ marginTop: '75px' }}
                      st
                      yle={{ width: '160px' }}
                    />
                  )}
                </Box>
              </Box>
              <Typography
                variant="h4"
                sx={{ color: '#00000080', fontFamily: 'Plus Jakarta Sans', fontWeight: '600', lineHeight: 4 }}
              >
                Order Details
              </Typography>
              {data ? (
              <TableContainer>
                <Table sx={{ minWidth: 100, border: '1px solid #f0f0f0' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Id</TableCell>
                      <TableCell align="center">Product Name</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Subtotal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell align="center" component="th" scope="row">
                            {row.ordervariant.sku}
                          </TableCell>
                          <TableCell align="center">{row.ordervariant.variant_name}</TableCell>
                          <TableCell align="center">{row.quantity}</TableCell>
                          <TableCell align="center">{row.ordervariant.price}</TableCell>
                          <TableCell align="center">{row.subtotal}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              ) : (
                <p>Loading data...</p> 
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box>
                  <Typography
                    sx={{
                      color: '#00000066',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '500',
                      fontSize: '15px',
                      marginTop: '30px',
                    }}
                  >
                    Payment Method
                  </Typography>

                  <Typography
                    sx={{
                      color: '#17dd19',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '500',
                      fontSize: '15px',
                      marginTop: '30px',
                      border: '1px solid #c4c5c4',
                      padding: '5px 20px 5px 20px',
                      borderRadius: '5px',
                      width: '162px',
                      textAlign: 'center',
                    }}
                  >
                    {paymentMethod.payment_method}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: '#00000066',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '400',
                      fontSize: '25px',
                      marginTop: '30px',
                    }}
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    sx={{
                      color: '#000',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '600',
                      fontSize: '25px',
                    }}
                  >
                    {' '}
                    <CurrencyRupeeIcon sx={{ paddingTop: '4px' }} /> {total.total_amount}
                  </Typography>
                </Box>
              </Box>
              <Button
                sx={{
                  backgroundColor: '#0FC5FF',
                  color: '#fff',
                  borderRadius: '5px',
                  padding: '5px 45px 5px 45px',
                  float: 'right',
                  marginTop: '20px',
                  ':hover': {
                    bgcolor: '#2065D1',
                    color: 'white',
                  },
                }}
                onClick={updateStatus}
              >
                Submit
              </Button>
            </CardContent>
          )}
        </Card>
      </Container>
    </>
  );
}
