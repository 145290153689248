import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Container,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Box,
  TextField,
  IconButton,
  Select,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  TableBody,
  Table,
  Popover,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import Iconify from '../components/iconify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function VariantsPage() {
  //const navigate = useNavigate();
  const location = useLocation();
  const { createdProduct, categoryName, subCategoryName } = location.state;
  const [error, setError] = useState(null);

  const { product_id } = useParams();

  const [variantName, setVariantName] = useState('');
  const [sku, setSku] = useState('');
  const [quantity, setQuantity] = useState('');
  const [quantityValid, setQuantityValid] = useState(true); // Initially valid
  const [quantityError, setQuantityError] = useState('');

  const [price, setPrice] = useState('');
  const [priceValid, setPriceValid] = useState(true); // Initially valid
  const [priceError, setPriceError] = useState('');

  //const [isMain, setIsMain] = useState([1, 0, 0, 0, 0]);
  //const [tag, setTag] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [productData, setProductData] = useState();
  const [setVariants, setSelectedVariants] = useState([]);

  const [selectedParameterHeaders, setSelectedParameterHeaders] = useState([]);
  const [selectedParameterValues, setSelectedParameterValues] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [submittedVariantIds, setSubmittedVariantIds] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [variantParameters, setVariantParameters] = useState([]);
  //const [addedVariantIds, setAddedVariantIds] = useState([]);
  const navigate = useNavigate();

  const handleOpenMenu = (event, variant_id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(variant_id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDeleteClick = (variant_id) => {
    setItemToDelete(variant_id);
    setDeleteDialogOpen(true);
    handleCloseMenu();
  };

  const handlevariantname = (event) => {
    setVariantName(event.target.value);
  };
  const handlesku = (event) => {
    setSku(event.target.value);
  };
  const handlequantity = (event) => {
    const value = event.target.value;
    setQuantity(value);

    if (isNaN(value) || parseInt(value) < 0) {
      setQuantityValid(false);
      setQuantityError('Quantity must be a non-negative integer.');
    } else {
      setQuantityValid(true);
      setQuantityError('');
    }
  };

  const handlePrice = (event) => {
    const value = event.target.value;
    setPrice(value);

    if (isNaN(value) || parseFloat(value) <= 0) {
      setPriceValid(false);
      setPriceError('Price must be a positive number.');
    } else {
      setPriceValid(true);
      setPriceError('');
    }
  };

  const [parameters, setParameters] = useState([]);

  const resetForm = () => {
    setSelectedImages([]);
    setVariantName('');
    setSku('');
    setQuantity('');
    setPrice('');
    //setIsMain([1, 0, 0]);
    setSelectedParameters([{ parameterName: '', parameterValue: '' }]);
    // setParameters([]);
    // setSelectedParameters([]);
  };
  const fetchparameters = async () => {
    try {
      const response = await fetch('https://khadi-backend.gitdr.com/api/parameters/');
      const data = await response.json();
      setParameters(data.parameters);
    } catch (error) {
      console.error('Error fetching parameters:', error);
    }
  };

  // const filteredParameters = parameters.filter((parameter) => parameter.parameter_name !== selectvariant);
  const [selectedParameters, setSelectedParameters] = useState([
    { parameterName: '', parameterValue: '' }, // Initial parameter object
  ]);

  const handleAddParameter = () => {
    const newParameters = [...selectedParameters];
    newParameters.push({
      parameterName: '',
      parameterValue: '',
    });
    setSelectedParameters(newParameters);
  };

  const handleParameterChange = (index, property, value) => {
    const newParameters = [...selectedParameters];
    newParameters[index][property] = value;
    setSelectedParameters(newParameters);
  };

  const handleDeleteParameter = (index) => {
    const newParameters = [...selectedParameters];
    newParameters.splice(index, 1);
    setSelectedParameters(newParameters);
  };

  const fetchProductData = async () => {
    try {
      const response = await axios.get(
        `https://khadi-backend.gitdr.com/api/products/${product_id}`,
        {
          headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE5LCJuYW1lIjoiQXNoYSIsImVtYWlsIjoiYXNoYXN1bm55ODlAZ21haWwuY29tIiwicm9sZSI6InVzZXIiLCJpYXQiOjE2OTE1NjI2MjUsImV4cCI6MTY5NDE1NDYyNX0.BJksF6WnuFPzlkdEP-Uw8IEgMH6ku-lAU7nOCHj-rDg
         `,
          },
        }
      );

      setProductData(response.data.data.productsWithDetails);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };
  useEffect(() => {
    fetchProductData();
    fetchparameters();
  }, []);

  const [selectedImages, setSelectedImages] = useState([]);
  //const fileInputRef = useRef(null);

  const handleFileSelect = (index, event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImagesArray = [...selectedImages];
      newImagesArray[index] = Array.from(files);
      setSelectedImages(newImagesArray);
    }
  };

  const variantsSubmit = async () => {
    try {
      if (
        !product_id ||
        !variantName ||
        !sku ||
        !quantity ||
        !price ||
        selectedParameters.length === 0 ||
        selectedImages.length === 0
      ) {
        alert('Please fill  all the required data.');
        return;
      }

      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('product_id', product_id);
      formData.append('variant_name', variantName);
      formData.append('sku', sku);
      formData.append('quantity', quantity);
      formData.append('price', price);
      //formData.append('isMain', isMain);

      // Convert selectedParameters array to the desired format
      const parametersObject = selectedParameters.reduce((obj, parameter) => {
        if (parameter.parameterName && parameter.parameterValue) {
          obj[parameter.parameterName] = parameter.parameterValue;
        }
        return obj;
      }, {});

      console.log(parametersObject);
      const parametersJSON = JSON.stringify(parametersObject);
      formData.append('parameters', parametersJSON);

      const parameterHeaders = selectedParameters.map((parameter) => parameter.parameterName);
      const parameterValues = selectedParameters.map((parameter) => parameter.parameterValue);

      setSelectedParameterHeaders(parameterHeaders);
      setSelectedParameterValues(parameterValues);

      selectedImages.forEach((ImagesArray, index) => {
        ImagesArray.forEach((image) => {
          formData.append(`image[]`, image);
        });
      });

      const response = await axios.post(
        `https://khadi-backend.gitdr.com/api/variants/createVariant`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Important for sending FormData
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE5LCJuYW1lIjoiQXNoYSIsImVtYWlsIjoiYXNoYXN1bm55ODlAZ21haWwuY29tIiwicm9sZSI6InVzZXIiLCJpYXQiOjE2OTE1NjI2MjUsImV4cCI6MTY5NDE1NDYyNX0.BJksF6WnuFPzlkdEP-Uw8IEgMH6ku-lAU7nOCHj-rDg
            `,
          },
        }
      );
      console.log(response.data); // Log the response object

      // Check for success message and handle accordingly

      if ((response.data.message = 'variant added successfully.')) {
        alert('variant added successfully!');
      } else {
        // console.log('response', response.data);
        setError('Failed to update variant.');

        if ((response.data.message = 'variant added successfully.')) {
          //setAddedVariantIds([...addedVariantIds, product_id]);
          alert('variant added successfully!');
        } else {
          console.log('response', response.data);
          setError('Failed to add variant.');
        }
      }
      fetchVariantData();
      setSelectedParameters([{ parameterName: '', parameterValue: '' }]);
      resetForm();
    } catch (error) {
      console.log(error);
      setError(error.response?.data?.message || 'Failed to add variant. Please try again later.');
    } finally {
      // Clear the flag and enable the submit button
      setIsSubmitting(false);
    }
  };

  const fetchVariantData = async () => {
    try {
      const response = await axios.get(
        `https://khadi-backend.gitdr.com/api/variants/product/${product_id}`
      );

      const variantsWithParameterValues = response.data.map((variant) => {
        const parameterValuesJSON = JSON.parse(variant.parameters);
        const parameterValues = selectedParameterHeaders.map(
          (parameterName) => parameterValuesJSON[parameterName] || ''
        );
        return { ...variant, parameterValues };
      });

      setSelectedVariants(variantsWithParameterValues);

      // const response = await axios
      //   .get(`https://app-f130c07b-f112-44a5-8549-7445fc613c8b.cleverapps.io/api/variants/product/${product_id}`)
      //   .then((response) => {
      //     setSelectedVariants(response.data);
      //     //setIsLoading(false);
      //     console.log('Added variants:', setSelectedVariants);
      //   });
    } catch (error) {
      console.log(error);

      //setIsLoading(false);
    }
  };

  const handleDeleteConfirmation = async () => {
    try {
      const apiUrl = `https://khadi-backend.gitdr.com/api/variants/${itemToDelete}`;
      await axios.delete(apiUrl);

      setSelectedVariants((prevRows) => prevRows.filter((variant) => variant.variant_id !== itemToDelete));

      alert('Variant deleted successfully!');
    } catch (error) {
      console.error('Failed to delete Variant:', error);
      alert('Failed to delete Variant. Please try again later.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCancelConfirmation = () => {
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <>
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          //alignItems="center"
          sx={{ padding: '10px 30px 5px 30px', fontFamily: 'Plus Jakarta Sans' }}
        >
          <Box sx={{ mb: 2, width: '370px' }}>
            <TextField label="Product ID" value={product_id} disabled fullWidth sx={{ mb: 2 }} />
            <TextField label="Product name" value={createdProduct.product_name} disabled fullWidth sx={{ mb: 2 }} />
            <TextField label="Description" value={createdProduct.description} disabled fullWidth />
          </Box>
          <Box sx={{ mb: 4, width: '370px' }}>
            <TextField label="Category" value={categoryName} disabled fullWidth sx={{ mb: 2 }} />
            <TextField label="Sub-Category" value={subCategoryName} disabled fullWidth />
          </Box>
        </Box>
        <Card sx={{ minWidth: 275, padding: '40px 40px 40px 40px' }}>
          <CardContent>
            <Typography>Variants</Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '20px',
                justifyContent: 'space-between',
                mb: 3,
              }}
            >
              <TextField
                label="Variant Name"
                value={variantName}
                variant="outlined"
                onChange={handlevariantname}
                sx={{ width: '370px' }}
                required
              />{' '}
              <TextField
                label="Stock Keeping Unit"
                value={sku}
                variant="outlined"
                onChange={handlesku}
                sx={{ width: '370px' }}
                required
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '20px',
                justifyContent: 'space-between',
                mb: 3,
              }}
            >
              <TextField
                label="Quantity"
                value={quantity}
                variant="outlined"
                onChange={handlequantity}
                sx={{ width: '370px' }}
                required
                error={!quantityValid}
                helperText={!quantityValid && quantityError}
              />{' '}
              <TextField
                label="Price"
                value={price}
                variant="outlined"
                onChange={handlePrice}
                sx={{ width: '370px' }}
                required
                error={!priceValid}
                helperText={!priceValid && priceError}
              />{' '}
            </Box>

            {selectedParameters.map((parameter, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mb: 5,
                }}
              >
                <Box>
                  <Select
                    label={`Parameter ${index + 1} Name`}
                    value={parameter.parameterName}
                    onChange={(event) => handleParameterChange(index, 'parameterName', event.target.value)}
                    sx={{ width: '280px', mb: '3' }} // Customize styling
                  >
                    {parameters.map((parameter) => (
                      <MenuItem key={parameter.id} value={parameter.parameter_name}>
                        {parameter.parameter_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <TextField
                    label={`Parameter ${index + 1} Value`}
                    value={parameter.parameterValue}
                    onChange={(event) => handleParameterChange(index, 'parameterValue', event.target.value)}
                    sx={{ width: '280px', mb: '3' }} // Customize styling
                  />
                </Box>

                <IconButton onClick={() => handleDeleteParameter(index)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            <Box>
              <Button
                variant="text"
                sx={{ float: 'left' }}
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleAddParameter}
              >
                Add Parameter
              </Button>
              <br />
            </Box>
            <br />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                pt: '{4}',
                textAlign: 'center',
              }}
            >
              {[...Array(5)].map((_, index) => (
                <Box
                  key={index}
                  width={148}
                  height={164}
                  border="0.3px solid gray"
                  justifyContent="center"
                  alignItems="center" // Center content vertically and horizontally
                  flexDirection="column" // Stack content vertically
                  position="relative" // Add relative positioning
                  sx={{
                    marginRight: 1,
                    overflow: 'hidden', // Hide overflow content
                  }}
                >
                  <label htmlFor={`file-input-${index}`}>
                    <AddIcon sx={{ fontSize: '100px', color: '#DDDEDF', cursor: 'pointer' }} placeholder="Click here" />
                  </label>

                  <input
                    id={`file-input-${index}`}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileSelect(index, event)}
                    accept="Images/*"
                    multiple
                  />

                  {selectedImages[index] && selectedImages[index].length > 0 && (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      {selectedImages[index].map((image, subIndex) => (
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`Preview ${index + 1}-${subIndex + 1}`}
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>Upload 6 images</Typography>
            <Button
              variant="contained"
              onClick={() => {
                variantsSubmit();
              }}
              disabled={isSubmitting} // Disable the button if submitting
              sx={{ float: 'right', marginTop: 3, mb: 3 }}
            >
              Submit
            </Button>
            {setVariants.length > 0 && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Variant Name</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>

                    {selectedParameterHeaders.map((parameterName, index) => (
                      <TableCell key={index}>{parameterName}</TableCell>
                    ))}
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {setVariants.map((variant) => {
                    const parametersObject = JSON.parse(variant.parameters);

                    return (
                      <TableRow key={variant.variant_id}>
                        <TableCell>
                          {variant.Images && variant.Images.length > 0 ? (
                            variant.Images.map((image, index) => (
                              <img
                                key={index}
                                src={image.image_url}
                                alt={`Image for ${variant.variant_name} (${index + 1})`}
                                style={{ width: '50px', height: '50px' }}
                              />
                            ))
                          ) : (
                            <span>No Images</span>
                          )}
                        </TableCell>
                        <TableCell>{variant.variant_name}</TableCell>
                        <TableCell>{variant.sku}</TableCell>
                        <TableCell>{variant.quantity}</TableCell>
                        <TableCell>{variant.price}</TableCell>
                        {selectedParameterHeaders.map((parameterName, index) => (
                          <TableCell key={index}>{parametersObject[parameterName] || '-'}</TableCell>
                        ))}
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleOpenMenu(event, variant.variant_id)}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                          <Popover
                            open={selectedRow !== null && selectedRow === variant.variant_id}
                            anchorEl={anchorEl}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => navigate(`/dashboard/VariantsPage/VariantsEdit/${variant.variant_id}`)}
                            >
                              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                              Edit
                            </MenuItem>
                            <MenuItem
                              sx={{ color: 'error.main' }}
                              onClick={() => {
                                handleDeleteClick(variant.variant_id);
                              }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableCell>{' '}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            )}
          </CardContent>
        </Card>
      </Container>
      <Dialog open={isDeleteDialogOpen} onClose={handleCancelConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this Variant?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}