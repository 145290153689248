import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Popover,
  MenuItem,
  IconButton,
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Paper,
  Table,
  Input,
  FormControl,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableContainer,
  TablePagination,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import NetworkHandler from 'src/utils/NetworkHandler';
import SearchIcon from '@mui/icons-material/Search';
import style from '../components/style/common.css';
// import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export default function MemberList() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [originalUserPosts, setOriginalUserPosts] = useState([]);

  const toggleStatus = async (userToUpdate) => {
    try {
      const updatedUser = { ...userToUpdate, status: userToUpdate.status === 'active' ? 'blocked' : 'active' };
      await NetworkHandler.makePutRequest(`/users/activateUser/${userToUpdate.user_id}`, {
        status: updatedUser.status,
      });
      setUserPosts((prevUsers) =>
        prevUsers.map((user) => (user.user_id === userToUpdate.user_id ? updatedUser : user))
      );
      alert('Status updated successfully!');
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to update status. Please try again later.');
    }
  };

  const handleToggleClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const alluser = [];
        const response = await NetworkHandler.makeGetRequest(`/users/getAll/?page=${page + 1}&pagesize=${rowsPerPage}`);
        const user = response.data.users;
        alluser.push(...user);
        setPageCount(response.data.totalPages);
        setRows(response.data.totalPages * rowsPerPage);
        setUserPosts(alluser);
        setOriginalUserPosts(alluser);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, rowsPerPage]);

  const handleSearch = async () => {
    try {
      setIsLoading(true);

      if (searchTerm.trim() === '') {
        // If the search term is empty, display all users
        const allUsers = await fetchAllUsers();
        setUserPosts(allUsers);
      } else {
        // Fetch all users across pages
        const allUsers = await fetchAllUsers();

        // Filter the user data based on the search term
        const filteredUsers = allUsers.filter((user) => {
          const searchRegex = new RegExp(searchTerm, 'i'); // Case-insensitive search
          return searchRegex.test(user.name) || searchRegex.test(user.user_name);
        });

        setUserPosts(filteredUsers);
      }
    } catch (error) {
      console.error('Failed to search users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to fetch all users across pages
  const fetchAllUsers = async () => {
    const allUsers = [];

    // Loop through all pages and fetch data for each page
    for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
      const response = await NetworkHandler.makeGetRequest(
        `/users/getAll/?page=${currentPage}&pagesize=${rowsPerPage}`
      );
      const users = response.data.users;
      allUsers.push(...users);
    }

    return allUsers;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Member List | Capcharts </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
            <TextField
              className="search-btn"
              label="Search Users...."
              variant="outlined"
              size="small"
              type="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress} // Handle Enter key press
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch} edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Link to={`https://captcharts.com/FeedPage?accessToken=${NetworkHandler.getAccessTokenDetails().accessToken}&refreshToken${NetworkHandler.getAccessTokenDetails().refreshToken}`}>
              <Button variant="contained" onClick={handleSearch} className="feedpage-btn">
                Feed Page
              </Button>
            </Link>
          </Stack>
        </Stack>
        <Scrollbar>
          <Card sx={{ minWidth: 900 }}>
            <CardContent>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">Users</TableCell>
                          <TableCell align="left"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userPosts.map((row) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                            <TableCell align="left">{row.user_id}</TableCell>
                            <TableCell align="left">
                              <span className="nameFontSize">{row.name}</span> <br />
                              <span className="userNameFontSize">{row.user_name}</span>
                            </TableCell>

                            <TableCell align="left">
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={row.status === 'active'}
                                      onChange={() => toggleStatus(row)}
                                      onClick={(event) => handleToggleClick(event)}
                                      sx={{
                                        '& .MuiSwitch-thumb': {
                                          color: row.status === 'active' ? 'green' : 'red',
                                        },
                                        '& .MuiSwitch-track': {
                                          backgroundColor: row.status === 'active' ? 'lightgreen' : 'lightcoral',
                                        },
                                      }}
                                    />
                                  }
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              {' '}
                              {row.status === 'inactive' && (
                                <span style={{ color: 'blue', marginLeft: '5px' }}>●</span>
                              )}{' '}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>
    </>
  );
}
