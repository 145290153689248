// ----------------------------------------------------------------------

const account = {

  // displayName: 'Username',
  // email: 'user@gmail.com',
  photoURL: '/assets/images/avatars/avatar_default.jpg',

};

export default account;
