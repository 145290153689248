import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  IconButton,
  Button,
  Container,
  Stack,
  Card,
  CardContent,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableContainer,
  TablePagination,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import NetworkHandler from 'src/utils/NetworkHandler';
import style from '../components/style/common.css';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';


const Usermanagement = () => {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false); // Dialog open state
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [userPosts, setUserPosts] = useState([]);
  const [newUserData, setNewUserData] = useState({
    name: '',
    username: '',
    phone: '',
    password: '',
    designation: '',
  });
  const [editUserData, setEditUserData] = useState({
    name: '',
    phone: '',
    designation: '',
  });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  
  const toggleStatus = async (userToUpdate) => {
    try {
      const updatedUser = { ...userToUpdate, status: userToUpdate.status === 'active' ? 'blocked' : 'active' };
      await NetworkHandler.makePutRequest(`/users/activateUser/${userToUpdate.user_id}`, {
        status: updatedUser.status,
      });
      setUserPosts((prevUsers) =>
        prevUsers.map((user) => (user.user_id === userToUpdate.user_id ? updatedUser : user))
      );
      alert('Status updated successfully!');
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to update status. Please try again later.');
    }
  };

  const handleToggleClick = (event) => {
    event.stopPropagation();
  };
  const handleAddUserClick = () => {
    setOpen(true);
  };

  const handleAddUser = async () => {
    try {
      const data = {
        name: newUserData.name,
        user_name: newUserData.username,
        phone: newUserData.phone,
        password: newUserData.password,
        designation: newUserData.designation,
      };
      console.log('Data sent:', data);
      const response = await NetworkHandler.makePostRequest('/users/add', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Response from server:', response.data);
      setOpen(false);
      // Optionally, you can refresh the user list or perform any other necessary actions
    } catch (error) {
      console.error('Failed to add user:', error);
      alert('Failed to add user. Please try again later.');
    }
  };

  const handleEditUser = (user) => {
    setEditUserData({
      name: user.name,
      phone: user.phone,
      designation: user.designation,
    });
    setSelectedRow(user);
    setIsEditDialogOpen(true);
  };
  

  const handleUpdateUser = async () => {
    try {
      const data = {
        name: editUserData.name,
        phone: editUserData.phone,
        designation: editUserData.designation,
      
      };
      console.log(data);
      await NetworkHandler.makePutRequest(`/users/${selectedRow.user_id}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setIsEditDialogOpen(false);
      // Optionally, you can refresh the user list or perform any other necessary actions
      alert('User updated successfully!');
    } catch (error) {
      console.error('Failed to update user:', error);
      alert(error);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const alluser = [];
        const response = await NetworkHandler.makeGetRequest(
          `/users/getAlladmin/?page=${page + 1}&pagesize=${rowsPerPage}`
        );
        const user = response.data.users;
        alluser.push(...user);
        setPageCount(response.data.totalPages);
        setRows(response.data.totalPages * rowsPerPage);
        setUserPosts(alluser);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    // Perform search logic here
    // You can use the searchTerm state to filter the userPosts array
    // Update the userPosts state with the filtered result
  };
 

  return (
    <>
      <Helmet>
        <title> Dashboard: User Management | Capcharts </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
            <Button variant="contained" onClick={handleAddUserClick} className="adduser-btn">
              Add User
            </Button>
            <Link to="https://captcharts.com/">
              <Button variant="contained" className="feedpage-btn">
                Feed Page
              </Button>
            </Link>
          </Stack>
        </Stack>
        <Scrollbar>
          <Card sx={{ minWidth: 900 }}>
            <CardContent>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">Users</TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userPosts.map((row) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                           
                            <TableCell align="left">{row.user_id}</TableCell>
                            
                            <TableCell align="left">
                              <span className="nameFontSize">{row.name}</span> <br />
                              <span className="userNameFontSize">{row.user_name}</span>
                            </TableCell>

                            <TableCell align="left">
                            <Tooltip title="edit">
                              <IconButton color="primary" onClick={() => handleEditUser(row)}>
                                <EditIcon />
                              </IconButton>
                              </Tooltip>
                            </TableCell>

                            <TableCell align="left">
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={row.status === 'active'}
                                      onChange={() => toggleStatus(row)}
                                      onClick={(event) => handleToggleClick(event)}
                                      sx={{
                                        '& .MuiSwitch-thumb': {
                                          color: row.status === 'active' ? 'green' : 'red',
                                        },
                                        '& .MuiSwitch-track': {
                                          backgroundColor: row.status === 'active' ? 'lightgreen' : 'lightcoral',
                                        },
                                      }}
                                    />
                                  }
                                />
                              </div>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>
      {/* add user */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the information for the new user.</DialogContentText>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newUserData.name}
            onChange={(e) => setNewUserData({ ...newUserData, name: e.target.value })}
          />
          <TextField
            label="UserName"
            fullWidth
            margin="normal"
            value={newUserData.username}
            onChange={(e) => setNewUserData({ ...newUserData, username: e.target.value })}
          />
          <TextField
            label="phone"
            fullWidth
            margin="normal"
            value={newUserData.phone}
            onChange={(e) => setNewUserData({ ...newUserData, phone: e.target.value })}
          />
          <TextField
            label="Designation"
            fullWidth
            margin="normal"
            value={newUserData.designation}
            onChange={(e) => setNewUserData({ ...newUserData, designation: e.target.value })}
          />
             <TextField
            label="Password"
            fullWidth
            margin="normal"
            value={newUserData.password}
            onChange={(e) => setNewUserData({ ...newUserData, password: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddUser} color="primary" variant="contained">
            Add User
          </Button>
        </DialogActions>
      </Dialog>

      {/* edit modal */}
      <Dialog open={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <DialogContentText>Update the user information.</DialogContentText>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={editUserData.name}
            onChange={(e) => setEditUserData({ ...editUserData, name: e.target.value })}
          />
          <TextField
            label="Phone"
            fullWidth
            margin="normal"
            value={editUserData.phone}
            onChange={(e) => setEditUserData({ ...editUserData, phone: e.target.value })}
          />
          <TextField
            label="Designation"
            fullWidth
            margin="normal"
            value={editUserData.designation}
            onChange={(e) => setEditUserData({ ...editUserData, designation: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditDialogOpen(false)} style={{ color: ' #CF796C '}}>
            Cancel
          </Button>
          <Button onClick={handleUpdateUser}  style={{ backgroundColor: ' #CF796C '}}  variant="contained">
            Update User
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Usermanagement;
