import axios from "axios";


function getStoredTokenDetails() {
  const storedTokenData = sessionStorage.getItem("accessToken");
  const storedUserName = sessionStorage.getItem("name");
  if (storedTokenData == null) return null;
  const { accessToken, refreshToken } = JSON.parse(storedTokenData);
  return { accessToken, refreshToken };
}
class NetworkHandler {
  #axios = axios.create({
    baseURL: "https://captcharts-backend.gitdr.com/api",
  });

  getAccessTokenDetails() {
    return getStoredTokenDetails();
  }

  constructor() {
    this.#axios.interceptors.request.use(
      (config) => {
        config.headers["Content-Type"] = "Application/json";
        if (this.getAccessTokenDetails()?.accessToken)
          config.headers.Authorization = `Bearer ${
            this.getAccessTokenDetails().accessToken
          }`;
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    this.#axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async (error) => {
        if (error.response?.status == 401 && this.getAccessTokenDetails()) {
          const requestBody = {
            refreshToken: this.getAccessTokenDetails().refreshToken,
          };
          const response = await this.makePostRequest(
            "refreshToken",
            requestBody
          );
          const accessTokenDetails = this.getAccessTokenDetails();
          accessTokenDetails.accessToken = response.data.accessToken;
          sessionStorage.setItem(
            "accessToken",
            JSON.stringify(accessTokenDetails)
          );
          return new Promise((resolve) => {
            const config = error.config;
            config.headers.Authorization = `Bearer ${accessTokenDetails.accessToken}`;
            resolve(this.#axios(config));
          });
        }
        return Promise.reject(error);
      }
    );
  }
  
  /**
   * 
   * @returns {Boolean} true if user is logged in
   */
  hasAccessToken() {
    return this.getAccessTokenDetails() != null
  }

  storeAccessTokenInfo(accessToken, refreshToken) {
    const storedTokenData = sessionStorage.setItem(
      "accessToken",
      JSON.stringify({ accessToken, refreshToken })
    );
  }

  makeGetRequest(url, headers) {
    return this.#axios.get(url, {
      headers,
    });
  }

  makePostRequest(url, body, headers) {
    return this.#axios.post(url, body, {
      headers,
    });
  }

  makePutRequest(url, body, headers) {
    return this.#axios.put(url, body, {
      headers,
    });
  }

  makeDeleteRequest(url, headers) {
    return this.#axios.delete(url, {
      headers,
    });
  }
}

export default new NetworkHandler();
