import React, { useState, useEffect } from 'react';
import {
  TextField,
  Box,
  Card,
  Container,
  Stack,
  Typography,
  Button,
  CardContent,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
//import VisibilityIcon from '@mui/icons-material/Visibility';
export default function VariantEdit() {
  const { variantId } = useParams();
  const navigate = useNavigate();

  const [variantData, setVariantData] = useState({
    variant_name: '',
    quantity: '',
    sku: '',
    price: '',
    parameters: '',
    image_url: '',
    //isMain: '',
  });

  const [parameters, setParameters] = useState([]);
  const [selectedParameters, setSelectedParameters] = useState([{ parameterName: '', parameterValue: '' }]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMain, setIsMain] = useState([1, 0, 0, 0, 0]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  //const [isHovered, setIsHovered] = useState(Array(5).fill(false));
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedImageFile, setEditedImageFile] = useState(null);
  const [editImageIndex, setEditImageIndex] = useState(null);
  const [imageFormData, setImageFormData] = useState(null);
  const { accessToken } = JSON.parse(sessionStorage.getItem('accessToken'));


  const fetchVariant = async () => {
    try {
     
      const response = await axios.get(`https://khadi-backend.gitdr.com/api/variants/getVariant/${variantId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
        },
      });

      const { variant_name, quantity, sku, price, parameters, image_url } = response.data.data.variants[0];
      setVariantData({
        variant_name: variant_name || '',
        quantity: quantity || '',
        sku: sku || '',
        price: price || '',
        parameters: parameters || '',
        image_url: image_url || '',
        //isMain: isMain || '',
      });

      const variantData = response.data.data.variants[0];
      const parametersArray = variantData.parameters.split(',').map((param) => {
        const [parameterName, parameterValue] = param.split(':');
        return {
          parameterName: parameterName.replace(/"/g, '').replace(/{|}/g, ''),
          parameterValue: parameterValue.replace(/"/g, '').replace(/{|}/g, ''),
        };

      });

      setVariantData(variantData);
      setSelectedParameters(parametersArray);
   

      const imageUrls = response.data.data.variants[0].Images.map((image) => ({
        imageUrl: image.image_url,
        isMain: image.isMain,
        image_id: image.image_id,
      }));

      setSelectedImages(imageUrls);
      console.log('image:', imageUrls);


      try {
        const parametersResponse = await fetch(`https://khadi-backend.gitdr.com/api/parameters/`);
        const parametersData = await parametersResponse.json();
        setParameters(parametersData.parameters);
        console.log('Fetched parameters data:', parametersData); // Log the entire fetched data
      } catch (error) {
        console.error('Error fetching parameters:', error);
      }

      console.log('variants:', variantData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchVariant();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVariantData((prevVariantData) => ({
      ...prevVariantData,
      [name]: value,
    }));
  };

  const handleClickOpen = (index) => {
    setSelectedImageIndex(index); // Store the index in a state variable
    setOpen(true);
  };

  const handleClickOpen1 = (index) => {
    setEditImageIndex(index);
    setEditDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose1 = () => {
    setEditDialogOpen(false);
  };


  const handleParameterChange = (index, property, value) => {
    const newParameters = [...selectedParameters];

    newParameters[index][property] = value;
    setSelectedParameters(newParameters);
  };

  const handleFileSelect = (index, event) => {
    const selectedFile = event.target.files[0]; // Get the selected file
    if (selectedFile) {
      const newFilesArray = [...files];
      newFilesArray[index] = selectedFile;
      setFiles(newFilesArray);

      const newImagesArray = [...selectedImages];
      newImagesArray[index] = { imageUrl: URL.createObjectURL(selectedFile) };
      setSelectedImages(newImagesArray);
    }
  };

  const handleFileEdit = (index, event) => {
    // Check if event is defined before accessing its properties
    if (event && event.target) {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        setEditedImageFile(selectedFile);

        
      }
    }
  };

  const handleImageUpload = async (imageIndex) => {
    try {
      
      const formData = new FormData();
      formData.append('image[]', files[imageIndex]);
      //console.log('Selected Image:', selectedImages);
      const apiUrl = `https://khadi-backend.gitdr.com/api/images/variants/${variantId}`;

      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('response:', response.data);
      alert('Image uploaded successfully!');
      handleClose();
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleImageEdit = async (imageIndex, imageId) => {
    try {
      const formData = new FormData();
      formData.append('image[]', editedImageFile); 

      const apiUrl = `https://khadi-backend.gitdr.com/api/images/${imageId}`;
      const response = await axios.put(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Image edited successfully:', response.data);
      alert('Image edited successfully!');
      setEditDialogOpen(false); 

      // Update the selectedImages state with the edited image URL
      const newSelectedImages = [...selectedImages];
      newSelectedImages[imageIndex] = { imageUrl: URL.createObjectURL(editedImageFile) };
      setSelectedImages(newSelectedImages);

    } catch (error) {
      console.error('Error editing image:', error);

    }
  };


  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
     
      const formData = new FormData();

      formData.append('product_id', variantData.product_id);
      formData.append('variant_name', variantData.variant_name);
      const parametersObject = selectedParameters.reduce((obj, parameter) => {
        if (parameter.parameterName && parameter.parameterValue) {
          obj[parameter.parameterName] = parameter.parameterValue;
        }
        return obj;
      }, {});

      console.log(parametersObject);
      const parametersJSON = JSON.stringify(parametersObject);
      formData.append('parameters', parametersJSON);
      formData.append('quantity', variantData.quantity);
      formData.append('sku', variantData.sku);
      formData.append('price', variantData.price);

      console.log('variantdata:', variantData);

      const apiUrl = `https://khadi-backend.gitdr.com/api/variants/${variantId}`;
      const response = await axios.put(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include access token in headers
          'Content-Type': 'multipart/form-data',

        },
      });
      console.log('response:', response.data);

      //if (response.data.message === 'Variant updated successfully.') {
      alert('Variant updated successfully!');

      //}
    } catch (error) {
      // console.error('Caught Error:', error);
      setError(error.response?.data?.message || 'Failed to update Variant. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteImage = async (image_id) => {
    try {
     
      const apiUrl = `https://khadi-backend.gitdr.com/api/images/${image_id}`;
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`, 
        },
      });
      const updatedImages = selectedImages.filter((image) => image.image_id !== image_id);
      setSelectedImages(updatedImages);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const confirmDeleteImage = (image_id) => {
    const confirmMessage = `Are you sure you want to delete this image?`;

    if (window.confirm(confirmMessage)) {
      handleDeleteImage(image_id);
    }
  };


  return (
    <>
      <Helmet>
        <title> Dashboard: Edit Variant | Captcharts </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Variant
          </Typography>
        </Stack>
        <Card sx={{ minWidth: 275, padding: '40px 40px 40px 40px' }}>
          <CardContent>
            <form onSubmit={handleUpdate}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <TextField
                  label="Variant Name"
                  name="variant_name"
                  value={variantData.variant_name}
                  variant="outlined"
                  onChange={handleChange}
                  sx={{ width: '370px' }}
                />{' '}
                <TextField
                  label="Stock Keeping Unit"
                  name="sku"
                  value={variantData.sku}
                  variant="outlined"
                  onChange={handleChange}
                  sx={{ width: '370px' }}
                />{' '}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '20px',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <TextField
                  label="Quantity"
                  name="quantity"
                  value={variantData.quantity}
                  variant="outlined"
                  onChange={handleChange}
                  sx={{ width: '370px' }}
                />{' '}
                <TextField
                  label="Price"
                  name="price"
                  value={variantData.price}
                  variant="outlined"
                  onChange={handleChange}
                  sx={{ width: '370px' }}
                />{' '}
              </Box>
              {selectedParameters.map((parameter, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mb: 5,
                  }}
                >
                  <Box>
                    <Select
                      label={`Parameter${index + 1}Name`}
                      value={parameter.parameterName}
                      onChange={(event) => handleParameterChange(index, 'parameterName', event.target.value)}
                      sx={{ width: '370px', mb: '3' }}
                    >
                      {parameters.map((paramOption) => (
                        <MenuItem key={paramOption.parameterName} value={paramOption.parameter_name}>
                          {paramOption.parameter_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <TextField
                      label={`Parameter ${index + 1} Value`}
                      value={parameter.parameterValue}
                      onChange={(event) => handleParameterChange(index, 'parameterValue', event.target.value)}
                      sx={{ width: '370px', mb: '3' }}
                    />
                  </Box>
                </Box>
              ))}

              <br />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  pt: '{4}',
                  textAlign: 'center',
                }}
              >
                {[...Array(5)].map((_, index) => (
                  <Box
                    key={index}
                    width={180}
                    height={200}
                    border={isMain[index] ? '2px solid blue' : '0.3px solid gray'}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    position="relative"
                    sx={{
                      marginRight: 1,
                      overflow: 'hidden',
                      borderRadius: '10px',
                    }}
                  >
                    {/* {selectedImages[index] && selectedImages[index].imageUrl && (
                      <Tooltip title="View Image">
                        <IconButton
                          onClick={() => viewImage(selectedImages[index].imageUrl)}
                          sx={{
                            position: 'absolute',
                            bottom: '5px',
                            left: 'calc(50% - 16px)', // Center the icon horizontally
                            zIndex: '0.1px',
                            padding: '4px',
                            borderRadius: '50%',
                            color: 'green',
                          }}
                        >
                          <VisibilityIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Tooltip>
                    )} */}

                    {selectedImages[index] && selectedImages[index].imageUrl && !selectedImages[index].isMain && (
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => confirmDeleteImage(selectedImages[index].image_id)}
                          sx={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '5px',
                            zIndex: '.10px',
                            padding: '4px',
                            borderRadius: '50%',
                            color: 'red',
                            //isplay: isHovered[index] ? 'block' : 'none',
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Tooltip>
                    )}

                    {selectedImages[index] && selectedImages[index].imageUrl && (
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => handleClickOpen1(index)}
                          sx={{
                            position: 'absolute',
                            bottom: '5px',
                            left: '5px',
                            // zIndex: '.10px',
                            padding: '1px',
                            borderRadius: '50%',
                            color: 'black',
                            //isplay: isHovered[index] ? 'block' : 'none',
                          }}
                        >
                          <EditIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Tooltip title="Add image">
                      <AddIcon
                        sx={{ fontSize: '100px', color: '#DDDEDF', cursor: 'pointer' }}
                        placeholder="Click here"
                        onClick={() => handleClickOpen(index)}
                      />
                    </Tooltip>

                    {selectedImages[index] && selectedImages[index].imageUrl && (
                      <Box
                        onClick={() => {
                          const fileInput = document.getElementById(`file-input-${index}`);
                          if (fileInput) {
                            fileInput.click();
                          }
                        }}
                        sx={{
                          width: '100%',
                          height: '80%',
                          overflow: 'hidden',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {selectedImages[index] && (
                          <img
                            key={index}
                            src={selectedImages[index].imageUrl}
                            alt={`Fetched Preview ${index + 1}`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                          />
                        )}
                      </Box>
                    )}
                    {selectedImages[index] && selectedImages[index].isMain && (
                      <Typography variant="caption" sx={{ marginTop: 1 }}>
                        Main Image
                      </Typography>
                    )}
                  </Box>
                ))}

              </Box>

              <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>Upload 5 images</Typography>
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#0FC5FF',
                  color: '#fff',
                  borderRadius: '5px',
                  padding: '5px 45px 5px 45px',
                  float: 'right',
                  marginTop: '40px',
                  ':hover': {
                    bgcolor: '#2065D1',
                    color: 'white',
                  },
                }}
                disabled={isLoading}
              >
                {isLoading ? 'Updating...' : 'Update'}
              </Button>
              {error && <Typography color="error">{error}</Typography>}
              <input
                id={`image-upload-${editImageIndex}`}
                type="file"
                style={{ display: 'none' }}
                onChange={(event) => handleFileEdit(editImageIndex, event)} // Pass the event object
                accept="image/*"
                multiple
              />

              <Dialog open={editDialogOpen} onClose={handleClose1}>
                <DialogTitle>Choose an image to upload:</DialogTitle>
                <DialogContent>
                  <label htmlFor={`image-upload-${editImageIndex}`}>
                    <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                      Upload New Image
                    </Button>
                  </label>
                  {selectedImages[editImageIndex] && (
                    <TextField
                      label="Selected Image"
                      value={selectedImages[editImageIndex].imageUrl}
                      fullWidth
                      variant="outlined"
                      disabled
                      sx={{ marginTop: 2 }}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose1} color="primary">
                    Close
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleImageEdit(editImageIndex, selectedImages[editImageIndex].image_id)}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Choose an image to upload:</DialogTitle>
                <DialogContent>
                  <input
                    id={`image-upload-${selectedImageIndex}`}
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileSelect(selectedImageIndex, event)}
                    accept="image/*"
                    multiple
                  />
                  <label htmlFor={`image-upload-${selectedImageIndex}`}>
                    <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                      Upload Image
                    </Button>
                  </label>
                  {selectedImages[selectedImageIndex] && (
                    <TextField
                      label="Selected Image"
                      value={selectedImages[selectedImageIndex].imageUrl}
                      fullWidth
                      variant="outlined"
                      disabled
                      sx={{ marginTop: 2 }}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Close
                  </Button>
                  <Button color="primary" onClick={() => handleImageUpload(selectedImageIndex)}>
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}