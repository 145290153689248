import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent, Button, Container, Typography, Tooltip, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import NetworkHandler from 'src/utils/NetworkHandler';
import Swal from 'sweetalert2';
import './ReportComment.css';
import './ReportedPost.css';
import { useNavigate, Link } from 'react-router-dom';

const ReportPost = () => {
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await NetworkHandler.makeGetRequest('/reports/listposts');
      setUserPosts(response.data);
    } catch (error) {
      console.error('Error fetching reported posts:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (reportId) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (confirmResult.isConfirmed) {
      try {
        await NetworkHandler.makeDeleteRequest(`/reports/${reportId}`);
        setUserPosts((prevPosts) => prevPosts.filter((post) => post.report_id !== reportId));
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleSearch = () => {
    // Perform search logic here
    // You can use the searchTerm state to filter the userPosts array
    // Update the userPosts state with the filtered result
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Report Post | Capcharts </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" mb={5}>
          <Link to="https://captcharts.com/FeedPage">
            <Button variant="contained" onClick={handleSearch} className="feedpage-btn">
              Feed Page
            </Button>
          </Link>
        </Stack>
        {loading ? (
          <CircularProgress />
        ) : (
          userPosts.map((post) => (
            <Card className="cardstyle" key={post.report_id}>
              <CardContent className="card_CardContent">
                <div>
                  <Typography variant="subtitle1">{post.Post.User.name}</Typography>
                  <div className="img-style">
                    {post.Post.Images && post.Post.Images.length > 0 && (
                      <img src={post.Post.Images[0].image_url} alt="Post Image" />
                    )}
                  </div>
                </div>

                <div className="number_of_reports">
                  <Button variant="outlined" color="error" style={{ borderColor: '#fff' }}>
                    {post.postReportCount} Report
                  </Button>
                  <Tooltip title="Delete Post" arrow>
                    <Button variant="contained" className="delete-btn" onClick={() => handleDelete(post.report_id)}>
                      Delete
                    </Button>
                  </Tooltip>
                </div>
              </CardContent>
            </Card>
          ))
        )}
      </Container>
    </>
  );
};
export default ReportPost;
