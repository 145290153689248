// component
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------


const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'MemberList',
    path: '/dashboard/app',
    icon: icon('ic_user-list'),
  },
  //{
    //title: 'user',
    //path: '/dashboard/user',
    //icon: icon('ic_user'),
  //},
  {
    title: 'Reported Post',
    path: '/dashboard/ReportedPost',
    icon: icon('ic_report-slash'),
  },
  {
    title: 'Reported Comment',
    path: '/dashboard/ReportComment',
    icon: icon('ic_vector'),

  },
 
  {
    title: 'Events',
    path: '/dashboard/Events',
    icon: icon('ic_event'),
   
  },

  {
    title: 'Gallery Images',
    path: '/dashboard/GalleryImages',
    icon: icon('ic_galleryimage'),
   
  },
  {
    title: 'User Management',
    path: '/dashboard/usermanagement',
    icon: icon('ic_user-multiple'),
  },
  {
    title: 'Posts Deleted by Admin',
    path: '/dashboard/PostDeletedByAdmin',
    icon: icon('ic_delete'),
  },
  
  // {
  //   title: 'banners',
  //   path: '/dashboard/banner',
  //   icon: icon('ic_banner'),
  // },
  //{
    //title: 'login',
    //path: '/login',
    //icon: icon('ic_lock'),
  //},
  //{
    //title: 'Not found',
    //path: '/404',
    //icon: icon('ic_disabled'),
  //},
];

export default navConfig;
