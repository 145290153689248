
// @mui
import { alpha, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  Box, 
  Link, 
  Divider, 
  Typography, 
  Stack, 
  MenuItem, 
  Avatar, 
  IconButton, 
  Popover, 
  Button, 
  TextField,
  InputAdornment } from '@mui/material';
  import { Visibility, VisibilityOff } from '@mui/icons-material';
// mocks_
import React, { useState, useEffect } from 'react';
import account from '../../../_mock/account';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import NetworkHandler from 'src/utils/NetworkHandler';
import "./AccountPopover.css";
const storedAccessToken = sessionStorage.getItem('accessToken');
const userName = sessionStorage.getItem('email');
const Name = sessionStorage.getItem('userName');
console.log("username",userName);
// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: 'eva:home-fill',
//   },
//   {
//     label: 'Profile',
//     icon: 'eva:person-fill',
//   },
//   {
//     label: 'Settings',
//     icon: 'eva:settings-2-fill',
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  
  const [open, setOpen] = useState(null);
  const location = useLocation();
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setshowCurrentPassword] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    // Retrieve userName when the component mounts
    const storedUserName = sessionStorage.getItem('email');
    setUserName(storedUserName);
  }, []);

  useEffect(() => {
    // Update userName if the session storage changes
    const storedUserName = sessionStorage.getItem('email');
    setUserName(storedUserName);
  }, [sessionStorage.getItem('email')]);

  const user = location.state && location.state.user;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };


  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenResetPasswordDialog = () => {
    setResetPasswordDialogOpen(true);
    setOpen(null);
    
  };

  const handleCloseResetPasswordDialog = () => {
    setResetPasswordDialogOpen(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setShowPassword(false);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
        const userName = sessionStorage.getItem('email');
    // Show a warning message before logging out
    Swal.fire({
      title: 'Are you sure you want to logout?',
      text: 'You will be logged out of your account.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, logout!',
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, perform logout
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('userName');
        navigate('/login');
      }
    });
  };


  const handleResetPassword = async () => {
    try {
      if (newPassword !== confirmPassword) {
       
        Swal.fire({
          icon: 'error',
          title: 'Password Mismatch',
          text: 'New password and confirm password do not match.',
          customClass: {
            container: 'my-swal'
          }
        });
        return; 
      }

      const response =await NetworkHandler.makePostRequest('/users/resetpassword',
      {
        currentPassword:currentPassword,
        newPassword:newPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      setResetPasswordDialogOpen(false);
      Swal.fire({
        icon: 'success',
        title: 'Password Updated',
        text: 'Your password has been updated successfully.',
      })
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
     
    } else if (response.status === 401) {
      // Incorrect password
      Swal.fire({
        icon: 'error',
        title: 'Incorrect Password',
        text: 'The provided password is incorrect.',
      });
    }

  } catch (error) {
    console.error("Error:", error.message);
  }
};

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user && user.user_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

       

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}
      
        <MenuItem  sx={{ m: 1 }}>
          {userName}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem onClick={handleOpenResetPasswordDialog} sx={{ m: 1 }}>
          Reset Password
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
        </Popover>
        <Dialog open={resetPasswordDialogOpen} onClose={handleCloseResetPasswordDialog}
        >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          {/* You can customize the content of the dialog based on your requirements */}
          <TextField
            label="Current Password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            type={showCurrentPassword ? 'text' : 'password'}
            margin="normal"
           
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setshowCurrentPassword(!showCurrentPassword)} edge="end">
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New Password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            margin="normal"
           
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetPasswordDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleResetPassword} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
        
   

    
     
    </>
  );
}

    

// call this refresh token  api refresh token : post : http://192.168.29.82:8080/api/refreshToken/  when Status: 401 Unauthorized  ,{
//   "message": "Access token is missing."
// }  this message is displayed