import { Helmet } from 'react-helmet-async';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  TextField,
  Typography,
  Card,
  CardContent,
  Badge,
  CircularProgress,
  Tooltip,
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useState, useEffect } from 'react';
import './Events.css';
import { Button } from '../components/Button';
import CloseIcon from '@mui/icons-material/Close';
import NetworkHandler from 'src/utils/NetworkHandler';
import Swal from 'sweetalert2';
import { useNavigate, Link } from 'react-router-dom';

const Events = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState('');
  const [isDialogTitleVisible, setDialogTitleVisible] = useState(true);
  const [isAddPhotoButtonVisible, setAddPhotoButtonVisible] = useState(true);
  const [files, setFiles] = useState(null);
  const [isSubmitCancelVisible, setSubmitCancelVisible] = useState(false);
  const [selectedDate, handleDateChange] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingEvent, setEditingEvent] = useState(null);
  const [isEditDialog, setEditDialog] = useState(false);

  const handleUploadButtonClick = () => {
    console.log('Button clicked');
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setAddPhotoButtonVisible(true);
    setSubmitCancelVisible(false);
    setDialogTitleVisible(true);
    setDialogOpen(false);
    setDialogTitleVisible(true);
    setFiles(null);
    setSelectedImage(null);
  };

  const editDialogClose = () => {
    setEditDialog(false);
  };

  const handleCancel = () => {
    setAddPhotoButtonVisible(true);
    setSubmitCancelVisible(false);
    setDialogTitleVisible(true);
    setFiles(null);
    setSelectedImage(null);
    setDialogOpen(false);
    setEditDialog(false);
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Set the selected file and its URL in the state
      setFiles(selectedFile);
      setSelectedImage({ imageUrl: URL.createObjectURL(selectedFile) });
      setDialogTitleVisible(false);
      setAddPhotoButtonVisible(false);
      setSubmitCancelVisible(true);
    }
  };

  const handlesubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('photo', files);
      formData.append('event_date', selectedDate);

      console.log('Submitting form data:', formData);
      const response = await NetworkHandler.makePostRequest('events/createEvent', formData);
      console.log('Server Response:', response);

      console.log(response);
      console.log('Image uploaded successfully:', response.data);
      handleDialogClose();
      fetchEvents();
    } catch (error) {
      console.error('Error uploading image:', error);

      if (error.response) {
        console.error('Server error details:', error.response.data);

        setDialogOpen(false);
      } else if (error.request) {
        console.error('No response received from the server');
      } else {
        console.error('Error details:', error.message);
      }
    }
  };

  const fetchEvents = async () => {
    try {
      const response = await NetworkHandler.makeGetRequest('/events/getAll');
      const data = response.data;
      setEvents(data.events);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDelete = (eventId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await NetworkHandler.makeDeleteRequest(`/events/${eventId}`);
          console.log('Event deleted successfully:', response.data);

          // Update the events list after deletion
          const updatedEvents = events.filter((event) => event.event_id !== eventId);
          setEvents(updatedEvents);

          Swal.fire('Deleted!', 'The image has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting event:', error);
          Swal.fire('Error', 'An error occurred while deleting the image.', 'error');
        }
      }
    });
  };

  const handleNotificationClick = async (eventId) => {
    try {
      await NetworkHandler.makePostRequest(`/events/${eventId}`);

      Swal.fire({
        icon: 'success',
        title: 'Notifications Sent!',
        text: 'Notifications have been sent to all users.',
      });
    } catch (error) {
      console.error('Error sending notifications:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while sending notifications.',
      });
    }
  };

  const handleEditClick = (event) => {
    setEditingEvent(event);
    handleDateChange(new Date(event.event_date).toISOString().split('T')[0]);
    setEditDialog(true);
  };

  const handleEdit = async () => {
    try {
      const formData = new FormData();
      formData.append('photo', files);
      formData.append('event_date', selectedDate);

      const eventId = editingEvent.event_id;

      console.log('Submitting form data:', formData);
      const response = await NetworkHandler.makePutRequest(`/events/${eventId}`, formData);

      setEditDialog(false);
      fetchEvents();
    } catch (error) {
      console.error('Error updating image and date:', error);

      if (error.response) {
        console.error('Server error details:', error.response.data);
        setDialogOpen(false);
      } else if (error.request) {
        console.error('No response received from the server');
      } else {
        console.error('Error details:', error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Events | Capcharts </title>
      </Helmet>
      <Container>
        <Box className="add_event">
          <Tooltip title="Add new event" arrow>
            <button className="cursor-pointer btn-event1" onClick={handleUploadButtonClick}>
              Upload Photo
            </button>
          </Tooltip>
          <div className="feed-page-button-container">
            <Tooltip title="Go to Feed Page" arrow>
              <Link to="https://captcharts.com/FeedPage">
                <Button className="feedpage-btn btn-event" style={{ backgroundColor: '#CF796C', color: '#FFFFFF' }}>
                  Feed Page
                </Button>
              </Link>
            </Tooltip>
          </div>
        </Box>

        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              <div className="closeBg1">
                <CloseIcon className="closeIcon  common-pointer" style={{ fontSize: '30px' }} />
              </div>
            </Button>
          </DialogActions>

          <DialogContent className="dialog_data">
            {!selectedImage && (
              <div className="upload_icon">
                <img src="/images/upload.png" alt="" />
              </div>
            )}

            {selectedImage && (
              <div className="selected_image_container">
                <img src={selectedImage.imageUrl} alt="Selected Image" className="selected_image" />
              </div>
            )}

            {selectedImage && (
              <div className="Description">
                <Typography>Select Event Date</Typography>
                <TextField
                  type="date"
                  fullWidth
                  value={selectedDate}
                  onChange={(e) => handleDateChange(e.target.value)}
                />
              </div>
            )}

            {isDialogTitleVisible && <DialogTitle>Drag files to upload</DialogTitle>}

            <Box className="upload_images">
              {isAddPhotoButtonVisible && (
                <Button
                  style={{ backgroundColor: '#CF796C' }}
                  shape="round"
                  className="feedpage-btn"
                  size="md"
                  onClick={() => document.getElementById('fileInput').click()}
                >
                  Add Photo
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                  />
                </Button>
              )}
            </Box>

            {isSubmitCancelVisible && (
              <Box className="submit_cancel_buttons">
                <Button
                  className="feedpage-btn"
                  shape="round"
                  style={{ backgroundColor: '#CF796C', marginRight: '10px' }}
                  size="md"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button className="feedpage-btn" shape="round" size="md" onClick={handlesubmit}>
                  Update
                </Button>
              </Box>
            )}

            <div className="max_img_size">JPEG file types only. Maximum image size 200mb</div>
          </DialogContent>
        </Dialog>

        {editingEvent && (
          <Dialog open={isEditDialog} onClose={editDialogClose} maxWidth="md" fullWidth>
            <DialogActions>
              <Button onClick={editDialogClose} color="primary">
                <div className="closeBg1">
                  <CloseIcon className="closeIcon  common-pointer" style={{ fontSize: '30px' }} />
                </div>
              </Button>
            </DialogActions>

            <DialogContent className="edit_dialog_data">
              <img src={editingEvent.event_img} alt="Event Image" />

              <div className="Description">
                <Typography className="event-edit-head">Select Event Date</Typography>
                <TextField type="date" value={selectedDate} onChange={(e) => handleDateChange(e.target.value)} />
              </div>

              <Box className="submit_cancel_buttons">
                <Button
                  className="cancel_button common-pointer cursor-pointer font-semibold mx-2 w-[200px] event-cancel"
                  size="md"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="submit_button common-pointer cursor-pointer font-semibold mx-1 w-[200px] event-update"
                  size="md"
                  onClick={handleEdit}
                >
                  Update
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        )}

        {loading ? (
          <CircularProgress />
        ) : (
          <div className="image-flex">
            {events.map((event) => (
              <Card className="cardstyle" key={event.event_id}>
                <div className="img-width" style={{ backgroundImage: `url(${event.event_img})` }}></div>
                <CardContent>
                  <Typography color="text.secondary">
                    Event Date: {new Date(event.event_date).toLocaleString()}
                  </Typography>
                  <Button
                    className="cursor-pointer edit-btn"
                    style={{ marginTop: '20px' }}
                    onClick={() => handleEditClick(event)}
                  >
                    Edit
                  </Button>
                  <button
                    className="cursor-pointer delete-btn"
                    style={{ marginTop: '20px', padding: ' 7px 45px' }}
                    onClick={() => handleDelete(event.event_id)}
                  >
                    Delete
                  </button>
                  <Button
                    className="notification"
                    color="inherit"
                    onClick={() => handleNotificationClick(event.event_id)}
                  >
                    <Badge color="error">
                      <NotificationsIcon className="cursor-pointer notification-icon" />
                    </Badge>
                  </Button>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </Container>
    </>
  );
};

export default Events;
