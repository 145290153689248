import { Helmet } from 'react-helmet-async';
// @mui
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardContent,
  TableContainer,
  TablePagination,
} from '@mui/material';

// loader,hooks,axios
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import axios from 'axios';
// components
import Iconify from '../components/iconify';
// import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/order';
// mock
// import POSTS from '../_mock/blog';
// import { set } from 'lodash';
import Scrollbar from '../components/scrollbar';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import NetworkHandler from 'src/utils/NetworkHandler';


export default function OrderPage() {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().slice(0, 10).replace('T', ' ');
    return formattedDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
         let currentPage = 1;
         const allOrders = [];
 
         while (true) {
        
        const response = await NetworkHandler.makeGetRequest(`orders/getAllOrders?page=${currentPage}&pagesize=10`);

          const orders = response.data.orders;

          if (orders.length === 0) {
            break;
          }

          allOrders.push(...orders);
          currentPage++;
        }
        setRows( allOrders);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: OrderPage | Captcharts </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Order list
          </Typography>
        </Stack>
        {/* order */}
        <Scrollbar>
          <Card sx={{ minWidth: 900, p: 4 }}>
            <CardContent>
              {isLoading ? ( // Display the loader while data is being fetched
                <CircularProgress />
              ) : (
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">Date</TableCell>
                          <TableCell align="left">Amount</TableCell>
                          <TableCell align="left">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.order_id}
                              component={Link}
                              to={`/dashboard/order/${row.order_id}`}
                              //  onClick={() => navigate(`/dashboard/order/${row.order_id}`)} // Navigate to OrderDetailsPage with the order ID as a parameter
                              style={{ cursor: 'pointer', textDecoration: 'none' }}
                            >
                              <TableCell align="left">{row.order_id}</TableCell>
                              <TableCell align="left"> {formatDate(row.order_date)}</TableCell>
                              <TableCell align="left">{row.total_amount}</TableCell>
                              <TableCell align="left">{row.status}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 35]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              )}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>
    </>
  );
}
