import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  Container,
  Typography,
  Box,
  TableRow,
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  MenuItem,
  Popover,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import axios from 'axios';
import Iconify from '../components/iconify';
import { useNavigate } from 'react-router-dom';
import { EventBusy } from '@mui/icons-material';

function ProductDetails() {
  const { product_id } = useParams();
  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [variants, setVariants] = useState([]);

  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  //const [setVariants, setSelectedVariants] = useState([]);

  const handleOpenMenu = (event, variant_id) => {
    event.preventDefault(); // Prevent the default behavior
    event.stopPropagation(); // Stop event propagation

    setAnchorEl(event.currentTarget);
    setSelectedRow(variant_id);
  };

  const handleCloseMenu = (event) => {
    event.preventDefault(); // Prevent the default behavior
    event.stopPropagation();
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDeleteClick = (event, variant_id) => {
    event.preventDefault(); // Prevent the default behavior
    event.stopPropagation();
    setItemToDelete(variant_id);
    setDeleteDialogOpen(true);
    handleCloseMenu(event);
  };

  const fetchProductData = async () => {
    try {
      const { accessToken } = JSON.parse(sessionStorage.getItem('accessToken'));

      const response = await axios.get(`https://khadi-backend.gitdr.com/api/products/getProduct/${product_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const variantsResponse = await axios.get(`https://khadi-backend.gitdr.com/api/variants/product/${product_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const productsArray = response.data.data.products;

      if (!Array.isArray(productsArray) || productsArray.length === 0) {
        setError(new Error('No product data found.'));
      } else {
        setProductData(productsArray[0]);
        setVariants(variantsResponse.data);
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProductData();
  }, [product_id]);

  const handleDeleteConfirmation = async () => {
    try {
      const { accessToken } = JSON.parse(sessionStorage.getItem('accessToken'));

      if (variants.length === 1) {
        alert("Can't delete, this is the last variant.Please delete the product");
      } else {
        const apiUrl = `https://khadi-backend.gitdr.com/api/variants/${itemToDelete}`;
        await axios.delete(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setVariants((prevRows) => prevRows.filter((variant) => variant.variant_id !== itemToDelete));

        alert('Variant deleted successfully!');
      }
    } catch (error) {
      console.error('Failed to delete Variant:', error);
      alert('Failed to delete Variant. Please try again later.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCancelConfirmation = () => {
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };
  const handleCancel = () => {
    navigate('/dashboard/Products');
  };

  return (
    <>
      <Container>
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h5">Product Details</Typography>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ padding: '10px 30px 5px 30px', fontFamily: 'Plus Jakarta Sans' }}
              key={productData.product_id} // Move the key to the outer element
            >
              <Box sx={{ width: '370px' }}>
                <p>Product ID: {productData.product_id}</p>
                <p>Product Name: {productData.product_name}</p>
                <p>Description: {productData.description}</p>
              </Box>
              <Box sx={{ width: '370px' }}>
                <p>Category: {productData.Category?.category_name}</p>
                <p>Subcategory: {productData.Subcategory?.subcategory_name}</p>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h6">Variants</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Variant Name</TableCell>
                    {/* <TableCell>SKU</TableCell> */}
                    <TableCell>Quantity</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {variants.map((variant) => {
                    console.log('Variant:', variant);

                    return (
                      <TableRow
                        hover
                        key={variant.variant_id}
                        onClick={() =>
                          navigate(
                            `/dashboard/ProductsPage/ProductDetails/ProductviewDetailsPage/${variant.variant_id}`
                          )
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell>
                          {variant.Images && variant.Images.length > 0 ? (
                            <img
                              src={variant.Images[0].image_url}
                              alt={`Image for ${variant.variant_name}`}
                              style={{ width: '50px', height: '50px' }}
                            />
                          ) : (
                            <span>No Images</span>
                          )}
                        </TableCell>
                        <TableCell>{variant.variant_name}</TableCell>
                        {/* <TableCell>{variant.sku}</TableCell> */}
                        <TableCell>{variant.quantity}</TableCell>
                        <TableCell>{variant.price}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => handleOpenMenu(event, variant.variant_id)}
                            style={{ cursor: 'pointer' }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                          <Popover
                            open={selectedRow !== null && selectedRow === variant.variant_id}
                            anchorEl={anchorEl}
                            onClose={(event) => handleCloseMenu(event, variant.variant_id)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <MenuItem
                              onClick={(event) => {
                                event.stopPropagation();
                                navigate(`/dashboard/VariantsPage/VariantsEdit/${variant.variant_id}`);
                                handleCloseMenu(event);
                              }}
                            >
                              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                              Edit
                            </MenuItem>
                            <MenuItem
                              sx={{ color: 'error.main' }}
                              onClick={(event) => {
                                handleDeleteClick(event, variant.variant_id);
                              }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableCell>{' '}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              sx={{
                float: 'right',
                marginTop: '25px', // Add 10px margin above the button
                marginBottom: '20px',
              }}
              onClick={() => navigate(`/dashboard/ProductsPage/ProductDetails/VariantAddPage/${product_id}`)}
            >
              Add Variant
            </Button>
            <Button
              variant="outlined"
              onClick={handleCancel}
              sx={{ float: 'right', marginTop: '25px', marginRight: 2, marginBottom: '20px' }}
            >
              Cancel
            </Button>
          </CardContent>
        </Card>
      </Container>
      <Dialog open={isDeleteDialogOpen} onCl ose={handleCancelConfirmation}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this Variant?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProductDetails;
