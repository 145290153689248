import { Helmet } from 'react-helmet-async';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  TextField,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Badge,
} from '@mui/material';
import { CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import './Events.css';
import { Button } from '../components/Button';
import CloseIcon from '@mui/icons-material/Close';
import NetworkHandler from 'src/utils/NetworkHandler';
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, Link } from 'react-router-dom';

const GalleryImages = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [description, setDescription] = useState('');
  const [isDialogTitleVisible, setDialogTitleVisible] = useState(true);
  const [isAddPhotoButtonVisible, setAddPhotoButtonVisible] = useState(true);
  const [files, setFiles] = useState(null);
  const [isSubmitCancelVisible, setSubmitCancelVisible] = useState(false);
  const [selectedDate, handleDateChange] = useState(null);
  const [events, setEvents] = useState([]);
  const [galleries, setgalleries] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleUploadButtonClick = () => {
    console.log('Button clicked');
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setAddPhotoButtonVisible(true);
    setSubmitCancelVisible(false);
    setDialogTitleVisible(true);
    setDialogOpen(false);
    setDialogTitleVisible(true);
    setDescription('');
    setFiles(null);
    setSelectedImage(null);
  };

  const handleCancel = () => {
    setAddPhotoButtonVisible(true);
    setSubmitCancelVisible(false);
    setDialogTitleVisible(true);
    setDescription('');
    setFiles(null);
    setSelectedImage(null);
    setDialogOpen(false);
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Set the selected file and its URL in the state
      setFiles(selectedFile);
      setSelectedImage({ imageUrl: URL.createObjectURL(selectedFile) });
      setDialogTitleVisible(false);
      setAddPhotoButtonVisible(false);
      setSubmitCancelVisible(true);
    }
  };

  const handlesubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('photo', files);
      formData.append('description', description);

      console.log('Submitting form data:', formData);
      const response = await NetworkHandler.makePostRequest('/galleries/addphoto', formData);
      console.log('Server Response:', response);

      console.log(response);
      Swal.fire('Success', 'Image uploaded successfully!', 'success');
      console.log('Image uploaded successfully:', response.data);
      handleDialogClose();
      fetchGallery();
    } catch (error) {
      console.error('Error uploading image:', error);

      if (error.response) {
        console.error('Server error details:', error.response.data);

        setDialogOpen(false);
      } else if (error.request) {
        console.error('No response received from the server');
      } else {
        console.error('Error details:', error.message);
      }
    }
  };

  const fetchGallery = async () => {
    try {
      const response = await NetworkHandler.makeGetRequest('/galleries/getAllGalleries');
      const data = response.data;
      console.log('data', data);
      setgalleries(data.galleries);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  const handleDelete = (imageId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await NetworkHandler.makeDeleteRequest(`/galleries/${imageId}`);
          console.log('Event deleted successfully:', response.data);

          const updatedGalley = galleries.filter((gallery) => gallery.gallery_id !== imageId);
          setgalleries(updatedGalley);

          Swal.fire('Deleted!', 'The image has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting event:', error);
          Swal.fire('Error', 'An error occurred while deleting the image.', 'error');
        }
      }
    });
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Gallery Image | Capcharts </title>
      </Helmet>
      <Container>
        <Box className="add_event ">
          <Tooltip title="Add new image" arrow>
            <button className="cursor-pointer  btn-event1" onClick={handleUploadButtonClick}>
              Upload Photo
            </button>
          </Tooltip>
          <div className="feed-page-button-container">
            <Tooltip title="Go to Feed Page" arrow>
              <Link to="https://captcharts.com/FeedPage">
                <Button className="feedpage-btn btn-event" style={{ backgroundColor: '#CF796C', color: '#FFFFFF' }}>
                  Feed Page
                </Button>
              </Link>
            </Tooltip>
          </div>
        </Box>

        <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              <div className="closeBg1">
                <CloseIcon className="closeIcon  common-pointer" style={{ fontSize: '30px' }} />
              </div>
            </Button>
          </DialogActions>

          <DialogContent className="dialog_data">
            {!selectedImage && (
              <div className="upload_icon">
                <img src="/images/upload.png" alt="" />
              </div>
            )}

            {selectedImage && (
              <div className="selected_image_container">
                <img src={selectedImage.imageUrl} alt="Selected Image" className="selected_image" />
              </div>
            )}

            {selectedImage && (
              <div className="Description">
                <TextField
                  label="Image Description"
                  fullWidth
                  multiline
                  rows={2}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            )}

            {isDialogTitleVisible && <DialogTitle>Drag files to upload</DialogTitle>}

            <Box className="upload_images">
              {isAddPhotoButtonVisible && (
                <Button
                  shape="round"
                  className="cursor-pointer feedpage-btn"
                  size="md"
                  onClick={() => document.getElementById('fileInput').click()}
                >
                  Add Photo
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                  />
                </Button>
              )}
            </Box>

            {isSubmitCancelVisible && (
              <Box className="submit_cancel_buttons">
                <Button
                  className="cancel_button common-pointer cursor-pointer font-semibold mx-2 w-[200px]"
                  shape="round"
                  color="green_300_01"
                  size="md"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="submit_button common-pointer cursor-pointer font-semibold mx-1 w-[200px]"
                  shape="round"
                  size="md"
                  onClick={handlesubmit}
                >
                  Submit
                </Button>
              </Box>
            )}

            <div className="max_img_size">JPEG file types only. Maximum image size 200mb</div>
          </DialogContent>
        </Dialog>

        {loading ? (
          <div className="loading-spinner">
            <CircularProgress />
          </div>
        ) : (
          <div className="image-flex">
            {galleries.map((gallery) => (
              <Card className="cardstyle" key={gallery.gallery_id}>
                <div className="img-width" style={{ backgroundImage: `url(${gallery.img_url})` }}></div>
                <CardContent>
                  {gallery.description && (
                    <Typography variant="subtitle1" gutterBottom>
                      {gallery.description}
                    </Typography>
                  )}

                  <Tooltip title="Delete image" arrow>
                    <button
                      className="cursor-pointer delete-btn"
                      style={{ padding: ' 7px 35px' }}
                      onClick={() => handleDelete(gallery.gallery_id)}
                    >
                      Delete
                    </button>
                  </Tooltip>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </Container>
    </>
  );
};

export default GalleryImages;
