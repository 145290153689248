import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Popover,
  MenuItem,
  IconButton,
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Paper,
  Table,
  Input,
  FormControl,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  TableContainer,
  TablePagination,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import NetworkHandler from 'src/utils/NetworkHandler';
// import SearchIcon from '@mui/icons-material/Search';
import style from '../components/style/common.css'


export default function Memberlist() {

  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(null);
  const Navigate = useNavigate();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userPosts, setUserPosts] = useState([]);
  
  

  const handleOpenMenu = (categoryId) => (event) => {
    setSelectedCategoryId(categoryId);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setSelectedCategoryId(null);
    setOpen(null);
  };


  const toggleStatus = async (productToUpdate) => {
    try {
      productToUpdate.active = !productToUpdate.active;
      await NetworkHandler.makePutRequest(`products/${productToUpdate.product_id}`);
      setRows(() => rows.map((row) => (row.product_id === productToUpdate.productId ? productToUpdate : row)));

      alert('Active status updated successfully!');
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to update status. Please try again later.');
    }
  };

  const handleToggleClick = (event) => {
    event.stopPropagation();
  };


  const fetchData = async () => {
    try {
      const response = await NetworkHandler.makeGetRequest('/users/getAll');
      const userData = response.data;
      setUserPosts(userData);
      console.log('User Data:', response.data);

    
      
      console.log('User Data:', userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };
  useEffect(() => {
  fetchData();
}, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (categoryId) => {
    handleCloseMenu();
    setItemToDelete(categoryId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      // const apiUrl = `https://khadi-backend.gitdr.com/api/categories/${itemToDelete}`;
      
      await NetworkHandler.makeDeleteRequest(`categories/${itemToDelete}`)

      // await axios.delete(apiUrl);

      setRows((prevRows) => prevRows.filter((row) => row.category_id !== itemToDelete));

      alert('Category deleted successfully!');
    } catch (error) {
      console.error('Failed to delete category:', error);
      alert('Failed to delete category. Please try again later.');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleCancelConfirmation = () => {
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Member List | Capcharts </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Member List
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => Navigate('/dashboard/category/addcategory')}>
            New Category
          </Button>
         {/* <div className="flex items-center justify-end  ">
            <div className="search_input bg-gray-200 items-center rounded-[25px] p-[11px] md:p-[8px] sm:p-[2px] flex">
            <Input 
                 placeholder="Search..." 
                 className="border-0  " />
            <SearchIcon />
            </div>
            </div> */}
        </Stack>
        <Scrollbar>
          <Card sx={{ minWidth: 900 }}>
            <CardContent>
              {/* {isLoading ? (
                <CircularProgress />
              ) : ( */}
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">ID</TableCell>
                          <TableCell align="left">Users</TableCell>
                          {/* <TableCell align="left">Category Image</TableCell>
                          <TableCell align="left">Description</TableCell> */}
                          <TableCell align="left"> </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userPosts.map((row) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.user_id}>
                            <TableCell align="left" >{row.user_id}</TableCell>
                            <TableCell align="left">
    <span className="nameFontSize">{row.name}</span> <br />
    <span className="userNameFontSize">{row.user_name}</span>
  </TableCell>                   
  <TableCell align="left">
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={row.active}
                                        onChange={(event) => toggleStatus(row)}
                                        onClick={(event) => handleToggleClick(event)}
                                        color="primary"
                                      />
                                    }
                                    // label={row.active ? 'Active' : 'Inactive'}
                                  />
                                </div>
                              </TableCell>   
                         </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                 
                </Paper>
              {/* )} */}
            </CardContent>
          </Card>
        </Scrollbar>
      </Container>
    </>
  );
}
