import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  CardContent,
  Button,  
  Container,
  Typography,
  Tooltip,
  CardMedia,
  Stack,
  TextField,
  
  
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import NetworkHandler from 'src/utils/NetworkHandler';
import Swal from 'sweetalert2'; 
import './ReportComment.css';
import { useNavigate, Link } from 'react-router-dom';



const PostDeletedByAdmin = () => {

  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
 
    const fetchData = async () => {
      try {      
        const response = await NetworkHandler.makeGetRequest('/users/DeletedPosts');
        setUserPosts(response.data);
      } catch (error) {
        console.error('Error fetching reported posts:', error);
      } finally {    
        setLoading(false);
      }
    };
    useEffect(() => {
    fetchData();
  }, []);
  const handleSearch = () => {
    // Perform search logic here
    // You can use the searchTerm state to filter the userPosts array
    // Update the userPosts state with the filtered result
  };

 
  return (
    <>
    <Helmet>
        <title> Dashboard: Post Deleted By Admin | Capcharts </title>
      </Helmet>
      <Container>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" mb={5}>
          <Link to="https://captcharts.com/">
            <Button variant="contained" onClick={handleSearch} className="feedpage-btn">
              Feed Page
            </Button>
            </Link>
          </Stack>
      <div className="image-flex">
        {loading ? (
          <CircularProgress />
        ) : (  
          userPosts.map(post => (
            <Card className="cardstyle" key={post.actiontaken_id}>
              <div
            className="img-width"
            style={{ backgroundImage: `url(${post.image_url})` }}
          ></div>

               
             
            </Card>
          ))
         
        )}
     </div>
      </Container>
    </>
  );
}
export default PostDeletedByAdmin;