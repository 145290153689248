import React from "react";
import PropTypes from "prop-types";

const shapes = { circle: "rounded-[50%]", round: "rounded-[37px]" };
const variants = {
  fill: {
    red_300: "bg-red-300 shadow-bs text-white-A700",
    white_A700: "bg-white-A700",
    blue_gray_700: "bg-blue_gray-700",
    red_300_6c: "bg-red-300_6c shadow-bs text-white-A700",
    red_200_6c: "bg-red-200_6c shadow-bs text-white-A700_6c",
    red_300_6c_01: "bg-red-300_6c_01 shadow-bs text-white-A700",
    red_200: "bg-red-200 shadow-bs text-white-A700",
    red_300_01: "bg-red-300_01 text-white-A700",
    black_900_4c: "backdrop-opacity-[0.5] bg-black-900_4c blur-[1.00px]",
  },
  outline: {
    red_300_01: "border border-red-300_01 border-solid text-red-300_01",
    red_300_6c_01: "border border-red-300_6c_01 border-solid text-red-300",
    red_300: "border border-red-300 border-solid text-red-300",
  },
};
const sizes = { xs: "p-1.5", sm: "p-2.5", md: "p-[17px]" };

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "sm",
  variant = "fill",
  color = "red_300",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "round"]),
  size: PropTypes.oneOf(["xs", "sm", "md"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf([
    "red_300",
    "white_A700",
    "blue_gray_700",
    "red_300_6c",
    "red_200_6c",
    "red_300_6c_01",
    "red_200",
    "red_300_01",
    "black_900_4c",
  ]),
};

export { Button };
