import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Container, TextField, Grid, Box, MenuItem, Button } from '@mui/material';

import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//import Link from '@mui/material';
import axios from 'axios';

const AddProduct = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');

  const [selectedValue, setSelectedValue] = useState('option1');

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [subCategories, setSubcategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleChangeProductName = (event) => {
    setProductName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleCancel = () => {
    navigate('/dashboard/Products');
  };

  const fetchCategory = async () => {
    try {
      const response = await axios.get('https://khadi-backend.gitdr.com/api/categories');
      setCategories(response.data.categories); // Assuming the response contains an array of category objects
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSubCategory = async (categoryId) => {
    try {
      console.log('Fetching subcategories for category ID:', categoryId);
      //console.log('Categories:', categories);
      const response = await axios.get(`https://khadi-backend.gitdr.com/api/subcategories/categories/${categoryId}`);
      console.log(response.data);
      setSubcategories(response.data);
    } catch (error) {
      console.log('Error fetching subcategories:', error);
    }
  };
  useEffect(() => {
    fetchCategory();
    //fetchSubCategory();
  }, [selectedCategory]);

  const handleChangeCategory = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(''); // Reset the selectedSubCategory when a new category is selected
    fetchSubCategory(event.target.value);
  };

  const productSubmit = async () => {
    if (!productName || !description || !selectedCategory || !selectedSubCategory || !selectedValue) {
      alert('Please fill in all the required fields.');
      return; // Exit the function early if validation fails
    }
    try {
      const productData = {
        product_name: productName,
        description: description,
        is_featured: 0,
        category_id: selectedCategory,
        subcategory_id: selectedSubCategory,
      };

      const response = await axios.post('https://khadi-backend.gitdr.com/api/products/createProduct/', productData, {
        headers: {
          'Content-Type': 'Application/json',
        },
      });
      const createdProduct = response.data.data;

      const selectedCategoryObj = categories.find((category) => category.category_id === selectedCategory);
      const categoryName = selectedCategoryObj ? selectedCategoryObj.category_name : '';

      const selectedSubCategoryObj = subCategories.find(
        (subcategory) => subcategory.subcategory_id === selectedSubCategory
      );
      const subCategoryName = selectedSubCategoryObj ? selectedSubCategoryObj.subcategory_name : '';

      alert('Success');
      const productId = createdProduct.product_id;
      navigate(`/dashboard/ProductsPage/AddProduct/VariantsPage/${productId}`, {
        // Pass the createdProduct as a prop to the next page
        state: { createdProduct, categoryName, subCategoryName },
      });
    } catch (error) {
      console.log('Error sending data to server:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Products
      </Typography>

      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '40px 40px 40px 40px',
            }}
          >
            <Box>
              <Typography>
                <TextField
                  label="Product Name"
                  required
                  value={productName}
                  onChange={handleChangeProductName}
                  variant="outlined"
                  sx={{ width: '450px' }}
                />{' '}
              </Typography>
              <br />

              <Typography>
                <TextField
                  multiline
                  rows={4}
                  label="Description"
                  required
                  value={description}
                  onChange={handleChangeDescription}
                  variant="outlined"
                  sx={{ width: 450 }}
                />
              </Typography>

              <Typography sx={{ fontFamily: 'Plus Jakarta Sans', color: '#00000099', fontSize: 13 }}>
                Select Your Product
              </Typography>
              <Typography st yle={{ fontFamily: 'Plus Jakarta Sans', fontSize: 13 }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="featuPlus Jakarta Sansred"
                    name="featured"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <FormControlLabel value="Featured" control={<Radio />} label="Featured" />
                      <FormControlLabel value="UnFeatured" control={<Radio />} label="UnFeatured" />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Typography>
            </Box>
            <Box>
              <Typography>
                <FormControl>
                  <TextField
                    label="Category"
                    required
                    select
                    value={selectedCategory}
                    onChange={(e) => {
                      handleChangeCategory(e); // Call the updated handleChangeCategory function
                    }}
                    sx={{ width: '280px' }}
                  >
                    {categories.length > 0 &&
                      categories.map((category) => (
                        <MenuItem key={category.id} value={category.category_id}>
                          {category.category_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Typography>
              <br />

              <Typography>
                <FormControl>
                  <TextField
                    label="Sub-Category"
                    required
                    select
                    value={selectedSubCategory}
                    onChange={(e) => setSelectedSubCategory(e.target.value)}
                    sx={{ width: '280px' }}
                  >
                    {' '}
                    {subCategories
                      // .filter((subcategory) => subcategory.categoryId === selectedCategory.id)
                      .map((subcategory) => (
                        <MenuItem key={subcategory.id} value={subcategory.subcategory_id}>
                          {subcategory.subcategory_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="outlined" onClick={handleCancel} sx={{ marginTop: 9, marginRight: 2 }}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={productSubmit} sx={{ marginTop: 9 }}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AddProduct;
