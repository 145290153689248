import React, { useState, useEffect } from 'react';
import {
  TextField,
  Box,
  Card,
  Container,
  Stack,
  Typography,
  Button,
  CardContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

export default function BannerEdit() {
  const { bannerId } = useParams();
  const navigate = useNavigate();

  const [bannerData, setBannerData] = useState({
    banner_img_url: '',
    link: '',
    expire_date: '',
  });

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        setIsLoading(true);
        if (bannerId) {
          const response = await axios.get(
            `https://khadi-backend.gitdr.com/api/banners/${bannerId}`
          );
          if (!response.data || !response.data.banner) {
            setError('Banner data not found.');
          } else {
            const { banner_img_url, link, expire_date } = response.data.banner;
            setBannerData({
              banner_img_url: banner_img_url || '',
              link: link || '',
              expire_date: formatDate(expire_date) || '',
            });
          }
        }
      } catch (error) {
        setError(error.message || 'Failed to fetch banner data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBanner();
  }, [bannerId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBannerData((prevBannerData) => ({
      ...prevBannerData,
      [name]: value,
    }));
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().slice(0, 10);
    return formattedDate;
  };

  const handleExpiryDateChange = (event) => {
    setBannerData((prevBannerData) => ({
      ...prevBannerData,
      expire_date: event.target.value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFile(file);
      setBannerData((prevData) => ({
        ...prevData,
        banner_img_url: URL.createObjectURL(file),
      }));
    } else {
      event.target.value = null;
      setFile(null);
      alert('Please select a valid image file.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const formData = new FormData();

      if (file) {
        formData.append('images', file);
      }
      formData.append('link', bannerData.link);
      formData.append('expire_date', bannerData.expire_date);

      console.log('formdata:', formData);
      for (const pair of formData.entries()) {
        console.log(`${pair[0]}, ${pair[1]}`);
      }
      const apiUrl = `https://khadi-backend.gitdr.com/api/banners/${bannerId}`;


      const response = await axios.put(apiUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.message = 'Banner updated successfully.') {
        alert('Banner updated successfully!');
        navigate('/dashboard/banner');
      } else {
        console.log("response", response.data);
        setError('Failed to update banner.');
      }
    } catch (error) {
      console.log(error);
      setError(
        error.response?.data?.message || 'Failed to update banner. Please try again later.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Dashboard: Edit Banner | Captcharts </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Edit Banner
          </Typography>
        </Stack>
        <Card sx={{ borderRadius: '5px', padding: '0px 80px 50px 80px' }}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  border: '1px solid #DDDEDF',
                  padding: '50px 50px 50px 50px',
                  margin: '40px 400px 0px 0px',
                  textAlign: 'center',
                  borderRadius: '5px',
                }}
              >
                <Typography htmlFor="file-input">
                  <AddIcon
                    sx={{ fontSize: '100px', color: '#DDDEDF', cursor: 'pointer' }}
                    placeholder="Click here"
                    onClick={() => document.getElementById('file-input').click()}
                  />
                  <input
                    id="file-input"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/*"
                    required={!bannerData.banner_img_url}
                  />
                  {bannerData.banner_img_url && (
                    <img
                      src={bannerData.banner_img_url}
                      alt="Banner Preview"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  )}
                </Typography>
              </Box>

              <Typography>370 X 177</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
                <Box sx={{ width: '500px' }}>
                  <TextField
                    id="link"
                    name="link"
                    label="Link"
                    variant="outlined"
                    placeholder="https:"
                    sx={{ width: '460px' }}
                    value={bannerData.link}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <TextField
                    type="date"
                    label="Expiry Date"
                    variant="outlined"
                    sx={{ width: '300px' }}
                    value={bannerData.expire_date}
                    onChange={handleExpiryDateChange}
                  />
                </Box>
              </Box>
              <Button
                type="submit"
                sx={{
                  backgroundColor: '#0FC5FF',
                  color: '#fff',
                  borderRadius: '5px',
                  padding: '5px 45px 5px 45px',
                  float: 'right',
                  marginTop: '40px',
                  ':hover': {
                    bgcolor: '#2065D1',
                    color: 'white',
                  },
                }}
                disabled={isLoading}
              >
                {isLoading ? 'Updating...' : 'Update'}
              </Button>
              {error && <Typography color="error">{error}</Typography>}
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
